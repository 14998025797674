import React from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import useStore from '../store/useStore'
import ContentHeader from '../components/Typography/ContentHeader'
import ContentText from '../components/Typography/ContentText'
import InnerLink from '../components/Typography/InnerLink'
import MediumImage from '../components/Image/MediumImage'
import SEO from '../layouts/Seo'

const HakkimizdaPage = () => {
  const { getImageByName } = useStore()

  return (
    <>
      <SEO
        title='Hakkımızda - Erez Kaynak'
        description='Firmamız Erez kaynak 2015 yılın da yenilenmeye giderek, günümüze kadar çelik hasır ve filtre imalat sektöründe yüksek titizlikle çalışarak özel bir yer edinmiştir.Paslanmaz 304 , paslanmaz 316 kalite yada dkp malzemeden, Müşterilerimizin istekleri doğrultusunda istenilen şekil, numune'
        keywords='hasır tel,hasır tel fiyatları,hasır tel imalatı,hasır tel kullanım alanları,kafes tel kullanım alanları,tel kafes kullanım alanları,paslanmaz hasır tel,hasır telleri,puntalı hasır tel,punta kaynaklı hasır tel,puntalı hasır tel fiyatları,hasır,tel,hasır tel imalatçısı,özel hasır tel,galvaniz hasır tel,hasır tel istanbul,tel hasır,kafes tel,tel hasır fiyatları,kafes tel fiyatları,hasır tel fiyatı'
        url='hakkimizda'
        imgurl={getImageByName('hasirtel13').sm.src}
      />

      <Box paddingX={3} paddingY={5}>
        <Grid container spacing={6} alignItems='flex-start'>
          <Grid item xs={12} sm={12} md={6} lg={7} xl={7}>
            <ContentHeader title='Hakkımızda' description='EreZ KAYNAK - çelik hasır ve filtre elek imalatı' />
            <ContentText>
              <p>
                Firmamız <strong>EreZ KAYNAK 2015</strong> yılında yenilenmeye giderek, günümüze kadar{' '}
                <strong>çelik hasır ve filtre imalat sektöründe</strong> yüksek titizlikle çalışarak özel bir yer edinmiştir.
              </p>
              <p>
                <strong>Paslanmaz 304 , paslanmaz 316 kalite ya da dkp malzemeden</strong> müşterilerimizin istekleri doğrultusunda
                istenilen şekil, numune ya da projeye göre <strong>Filtre ve Elek imalatı</strong> gerçekleştirmekteyiz.
              </p>
              <p>
                <strong>Paslanmaz, galvanize ya da çelik telden,</strong> müşterilerimizin istekleri doğrultusunda istenilen tel
                kalınlığında ve ebatta, göz aralığı fark etmeksizin <strong>punta kaynaklı hasır(çesan)</strong> imalat
                gerçekleştirmekteyiz.
              </p>
            </ContentText>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={5} xl={5}>
            <MediumImage lazyHeight={500} img={getImageByName('hasirtel13')} alt='çelik hasır tel imalatı erez kaynak' />
          </Grid>
        </Grid>
        <Box>
          <ContentText>
            <p>
              Makinelerimizin kalıpları en büyük ölçüsü 2200x3000mm ebatında olup, göz aralıkları <strong>15mm, 20mm, 25mm</strong>{' '}
              ölçülerinde ve katlarında (Örn; 25×50 50×50 15×45 15×75 vb.) ya da siparişe göre özel kalıp yapılarak istenilen göz aralığında
              ve ebatlarda <strong>paslanmaz, galvanize ya da siyah çelik tel</strong> malzemesinden üretim gerçekleştirebiliyoruz.
            </p>
          </ContentText>
        </Box>
        <Box textAlign='center'>
          <InnerLink title='İletişim Bilgilerimiz İçin Tıklayınız' url='/iletisim' />
        </Box>
      </Box>
    </>
  )
}
export default HakkimizdaPage
